import React, { useEffect, useState } from 'react';
import { Autocomplete, Button, IconButton, List, ListItem, ListItemText, TextField } from '@mui/material';
import axios from 'axios';
import * as DateTime from 'luxon';
import DeleteIcon from '@mui/icons-material/Delete';
import { io } from 'socket.io-client';

const socketUrl = `${process.env.REACT_APP_API_URL_OVERRIDE || process.env.REACT_APP_API_URL}/players`.replace('http', 'ws');
const socket = io(socketUrl);

// filter out anything not name#000 format
const regex = /[a-zA-Z]+#\d+/g

interface PlayerInfo {
    id: string;
}

interface LeagueInfo {
    id: string;
}

interface QueueItem {
    player: string;
    leagues: string[];
}

function Queue() {
    const [leagues, setLeagues] = useState([]);
    const [players, setPlayers] = useState([]);
    const [currentQueue, setCurrentQueue] = useState([]);
    const [playerValue, setPlayerSelect] = React.useState<PlayerInfo[]>([]);
    const [leagueValue, setLeagueSelect] = React.useState<LeagueInfo[]>([]);

    const fetchPlayerData = () => {
        axios.request({
            url: `${process.env.REACT_APP_API_URL_OVERRIDE || process.env.REACT_APP_API_URL}/players`,
            method: 'GET'
        })
            .then((res) => {
                setPlayers(res.data);
            })
            .catch((res) => {
                console.log(res);
            });
    };

    const fetchCurrentQueue = () => {
        axios.request({
            url: `${process.env.REACT_APP_API_URL_OVERRIDE || process.env.REACT_APP_API_URL}/queue`,
            method: 'GET'
        })
            .then((res) => {
                setCurrentQueue(res.data);
            })
            .catch((res) => {
                console.log(res);
            });
    }

    const fetchLeagueData = () => {
        axios.request({
            url: `${process.env.REACT_APP_API_URL_OVERRIDE || process.env.REACT_APP_API_URL}/leagues`,
            method: 'GET'
        })
            .then((res) => {
                setLeagues(res.data);
            })
            .catch((res) => {
                console.log(res);
            });
    };

    const addToQueue = () => {
        let p = playerValue.map((v) => v.id);
        let l = leagueValue.map((v) => v.id);

        axios.request({
            url: `${process.env.REACT_APP_API_URL_OVERRIDE || process.env.REACT_APP_API_URL}/queue`,
            method: 'POST',
            data: {
                players: p,
                leagues: l
            }
        })
            .then((res) => {
            })
            .catch((res) => {
                console.log(res);
            });
    }

    const removeFromQueue = (qi: QueueItem) => {
        axios.request({
            url: `${process.env.REACT_APP_API_URL_OVERRIDE || process.env.REACT_APP_API_URL}/queue`,
            method: 'Delete',
            data: {
                players: [qi.player],
            }
        })
            .then((res) => {
            })
            .catch((res) => {
                console.log(res);
            });
    }

    const generateListItem = (currentQueue: QueueItem[]) => {
        return currentQueue.map((v, i) => {
            const now = DateTime.DateTime.local();
            let updatesAt = now.plus({ minutes: i + 1 });

            return (
                <ListItem key={v.player}>
                    <IconButton
                        edge="start"
                        aria-label="delete"
                        onClick={() => removeFromQueue(v)}
                    >
                        <DeleteIcon />
                    </IconButton>
                    <ListItemText>
                        {i + 1}: {v.player} {
                            (v.leagues.length > 0) &&
                            <span> ({v.leagues.join(",")}) </span>
                        } {`Updating at ${updatesAt.toFormat('HH:mm')}`}
                    </ListItemText>
                </ListItem>
            )
        })
    }

    useEffect(() => {
        fetchLeagueData();
        fetchPlayerData();
        fetchCurrentQueue();

        socket.on('queue', () => {
            fetchCurrentQueue();
        });

        return () => {
            socket.off('queue');
        };
    }, []);

    return (
        <div>
            <Autocomplete
                multiple
                id="players-select"
                disableCloseOnSelect
                options={players}
                value={playerValue}
                getOptionLabel={(option: any) => option.id}
                renderInput={(params) => (
                    <TextField {...params} label="Players" placeholder="New Player" />
                )}
                freeSolo
                onChange={(event, newValue) => {
                    if (Array.isArray(newValue)) {
                        let vals = newValue
                            .filter((v: any) => {
                                if (typeof v === 'string') {
                                    return regex.test(v);
                                }
                                return true;
                            })
                            .map<PlayerInfo>((v: any) => {
                                if (typeof v === 'string') {
                                    return {
                                        id: v
                                    }
                                } else {
                                    return v;
                                }
                            })
                        setPlayerSelect(vals);
                    }
                }}
            />
            <Autocomplete
                multiple
                id="leagues-select"
                disableCloseOnSelect
                options={leagues}
                getOptionLabel={(option: any) => option.name}
                renderInput={(params) => (
                    <TextField {...params} label="Leagues" placeholder="" />
                )}
                value={leagueValue}
                onChange={(event, newValue) => {
                    if (Array.isArray(newValue)) {
                        setLeagueSelect(newValue);
                    }
                }}
            />
            <Button
                variant='contained'
                onClick={addToQueue}
            >
                Add to queue
            </Button>

            <h2>
                Current Queue
            </h2>
            <List>
                {generateListItem(currentQueue)}
            </List>
        </div>
    );
}

export default Queue
