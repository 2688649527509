import * as React from 'react';
import { PlayerInfo } from '../utils/playerInfo';
import { getRank } from '../utils/rank';
import anime from 'animejs';
import './player.css';
import { None } from '../images/ranks/images';

interface playerDisplayProps {
    player: PlayerInfo;
    height: number;
    width: number;
    isOverlay: boolean;
}

function PlayerDisplay(props: playerDisplayProps) {
    const [oldPlayerData, setOldPlayerData] = React.useState<PlayerInfo>(props.player);
    const [, setRatingRef] = React.useState<ReturnType<typeof anime> | undefined>();
    const [, setWinRef] = React.useState<ReturnType<typeof anime> | undefined>();
    const [, setLossRef] = React.useState<ReturnType<typeof anime> | undefined>();
    const [, setWinLossRef] = React.useState<ReturnType<typeof anime> | undefined>();
    const [rankAnimation, setRankAnimationRef] = React.useState<ReturnType<typeof anime> | undefined>();
    const [titleAnimation, setTitleAnimationRef] = React.useState<ReturnType<typeof anime> | undefined>();
    const [imageRef, setImageRef] = React.useState("");
    const [titleRef, setTitleRef] = React.useState("Rank");
    const [, setRatingDiffAnimationRef] = React.useState<ReturnType<typeof anime> | undefined>();
    const [ratingDiff, setRatingDiff] = React.useState(0);
    const [scaleRatio, setScaleRatio] = React.useState(1);

    React.useLayoutEffect(() => {
        setWinLossRef(anime({
            targets: '.winLoss',
            opacity: 1,
            easing: 'easeInOutQuint',
            duration: 5000,
        }));
    }, []);

    React.useEffect(() => {
        setRatingRef(anime({
            targets: '.rating',
            textContent: props.player.rating,
            round: 10,
            opacity: 1,
            easing: 'easeInOutQuint',
            duration: 4000,
        }));
        setWinRef(anime({
            targets: '.wins',
            textContent: props.player.wins,
            round: 1,
            opacity: 1,
            easing: 'easeInOutQuad',
            duration: 2000,
        }));
        setLossRef(anime({
            targets: '.losses',
            textContent: props.player.losses,
            round: 1,
            opacity: 1,
            easing: 'easeInOutQuad',
            duration: 2000,
        }));

        let rank = getRank(props.player.rating, props.player.wins + props.player.losses, props.player.isGrandMaster || false);
        let oldRank = getRank(oldPlayerData.rating, oldPlayerData.wins + oldPlayerData.losses, oldPlayerData.isGrandMaster || false);

        // This checks if the initial display should be set to 'not yet ranked' since that is kind of a weird scenario
        if (!imageRef && props.player.id) {
            setImageRef(rank.image);
            setTitleRef(rank.title);
            if (rank.image === None) {
                setRankAnimationRef(anime({
                    targets: '.rank',
                    duration: 1500,
                    delay: 3150,
                    opacity: 1,
                    easing: 'linear',
                }));
                setTitleAnimationRef(anime({
                    targets: '.title',
                    duration: 1500,
                    delay: 3150,
                    opacity: 1,
                    easing: 'linear',
                }));
            }
        }

        if (rank.image !== oldRank.image && rank.image !== imageRef) {
            rankAnimation?.pause();
            setRankAnimationRef(anime({
                targets: '.rank',
                delay: 2000,
                duration: 1000,
                opacity: 0,
                easing: 'easeInQuint',
                complete: function (a) {
                    setImageRef(rank.image);
                    setRankAnimationRef(anime({
                        targets: '.rank',
                        duration: 1500,
                        delay: 150,
                        opacity: 1,
                        easing: 'linear',
                    }));
                }
            }));
        }

        if (rank.title !== oldRank.title && rank.title !== titleRef) {
            titleAnimation?.pause();
            setTitleAnimationRef(anime({
                targets: '.title',
                delay: 2000,
                duration: 1000,
                opacity: 0,
                easing: 'easeInQuint',
                complete: function (a) {
                    setTitleRef(rank.title);
                    setTitleAnimationRef(anime({
                        targets: '.title',
                        duration: 1500,
                        delay: 150,
                        opacity: 1,
                        easing: 'linear',
                    }));
                }
            }));
        }

        let diff = props.player.rating - oldPlayerData.rating;

        if (oldPlayerData.rating && diff) {
            setRatingDiff(+diff.toFixed(1));
            setRatingDiffAnimationRef(anime({
                targets: '.ratingChange',
                delay: 3000,
                duration: 1000,
                opacity: 1,
                easing: 'linear',
                complete: function (a) {
                    setRatingDiffAnimationRef(anime({
                        targets: '.ratingChange',
                        duration: 1000,
                        opacity: 0,
                        easing: 'linear',
                        delay: 4000,
                    }));
                }
            }));
        }

        setOldPlayerData(props.player);

        if (props.isOverlay) {
            if (props.height < props.width) {
                let val = props.height / 155;
                setScaleRatio(val);
            }
            else {
                let val = props.width / 150;
                setScaleRatio(val);
            }
        }

    }, [oldPlayerData, props, imageRef, titleRef, rankAnimation, titleAnimation])

    return (
        <div className='playerDisplayBoxContainer' style={props.isOverlay ? { width: props.width, height: props.height } : {}}>
            <div
                className='playerDisplayBox'
                style={{ transform: `scale(${scaleRatio})` }}
            >
                <div>
                    <img className='rank startHidden' src={imageRef} style={{ height: '80px' }} alt={titleRef} />
                </div>
                <div>
                    <span className='title startHidden'>{titleRef}</span>
                </div>
                <div>
                    <span className='rating startHidden'>0</span> <span className={`ratingChange startHidden ${ratingDiff < 0 ? 'negative' : 'positive'}`}>{`${ratingDiff < 0 ? "" : "+"}${ratingDiff}`}</span>
                </div>
                <div className='winLoss startHidden'>
                    <span className='wins'>0</span>W/<span className='losses'>0</span>L
                </div>
            </div>
        </div>
    )
}

export default PlayerDisplay;
