import { Tooltip } from '@mui/material';
import * as React from 'react';
import { Bronze1, Bronze2, Bronze3, Diamond1, Diamond2, Diamond3, Gold1, Gold2, Gold3, Grandmaster, Master1, Master2, Master3, None, Platinum1, Platinum2, Platinum3, Silver1, Silver2, Silver3 } from '../images/ranks/images';
import './rank.css'

interface RankInfo {
    title: string;
    lowerBound: number;
    upperBound: number;
    image: string;
}

// if these are going to change they can be stored in the DB
// https://twitter.com/Fizzi36/status/1603852048239595520
// https://cdn.discordapp.com/attachments/871461841002446910/1053334359056318564/IMG_1656.webp
const ranks: RankInfo[] = [
    {
        title: "Not yet ranked",
        lowerBound: 0,
        upperBound: 0,
        image: None
    },
    {
        title: "Bronze 1",
        lowerBound: 0,
        upperBound: 766,
        image: Bronze1
    },
    {
        title: "Bronze 2",
        lowerBound: 766,
        upperBound: 914,
        image: Bronze2
    },
    {
        title: "Bronze 3",
        lowerBound: 914,
        upperBound: 1055,
        image: Bronze3
    },
    {
        title: "Silver 1",
        lowerBound: 1055,
        upperBound: 1189,
        image: Silver1
    },
    {
        title: "Silver 2",
        lowerBound: 1189,
        upperBound: 1316,
        image: Silver2
    },
    {
        title: "Silver 3",
        lowerBound: 1316,
        upperBound: 1436,
        image: Silver3
    },
    {
        title: "Gold 1",
        lowerBound: 1436,
        upperBound: 1549,
        image: Gold1
    },
    {
        title: "Gold 2",
        lowerBound: 1549,
        upperBound: 1654,
        image: Gold2
    },
    {
        title: "Gold 3",
        lowerBound: 1654,
        upperBound: 1752,
        image: Gold3
    },
    {
        title: "Platinum 1",
        lowerBound: 1752,
        upperBound: 1843,
        image: Platinum1
    },
    {
        title: "Platinum 2",
        lowerBound: 1843,
        upperBound: 1928,
        image: Platinum2
    },
    {
        title: "Platinum 3",
        lowerBound: 1928,
        upperBound: 2004,
        image: Platinum3
    },
    {
        title: "Diamond 1",
        lowerBound: 2004,
        upperBound: 2074,
        image: Diamond1
    },
    {
        title: "Diamond 2",
        lowerBound: 2074,
        upperBound: 2137,
        image: Diamond2
    },
    {
        title: "Diamond 3",
        lowerBound: 2137,
        upperBound: 2192,
        image: Diamond3
    },
    {
        title: "Master 1",
        lowerBound: 2192,
        upperBound: 2275,
        image: Master1
    },
    {
        title: "Master 2",
        lowerBound: 2275,
        upperBound: 2350,
        image: Master2
    },
    {
        title: "Master 3",
        lowerBound: 2350,
        upperBound: 5000,
        image: Master3
    },
    {
        title: "Grandmaster",
        lowerBound: 5000,
        upperBound: 10000,
        image: Grandmaster
    },
]

export function getRank(rating: number, gamesPlayed: number, isGrandMaster: boolean = false) {
    if (gamesPlayed < 5) return ranks[0];
    if (isGrandMaster) return ranks[ranks.length-1];
    for (let r of ranks) {
        if (r.lowerBound <= rating && r.upperBound > rating) {
            return r;
        }
    }
    return ranks[0];
}

interface RankProps {
    rating: number;
    gamesPlayed: number;
}

export default function Rank(props: RankProps) {
    let rank = getRank(props.rating, props.gamesPlayed);
    return (
        <div className='rankBox'>
            <Tooltip title={rank.title}>
                <img src={rank.image} style={{ width: '30px' }} alt={rank.title} />
            </Tooltip>
            <div>
                {props.rating}
            </div>
        </div>
    )
}
