import * as React from 'react';
import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { io } from 'socket.io-client';
import './player.css';
import PlayerDisplay from './playerDisplay';
import { PlayerInfo } from '../utils/playerInfo';
import useWindowDimensions from '../utils/windowDimensions';

const socketUrl = `${process.env.REACT_APP_API_URL_OVERRIDE || process.env.REACT_APP_API_URL}/players`.replace('http', 'ws');

const socket = io(socketUrl);

function Player() {
    const [playerInfo, setPlayerInfo] = useState<PlayerInfo>({
        name: '',
        id: '',
        wins: 0,
        losses: 0,
        rating: 0,
        isGrandMaster: false
    });
    const location = useLocation();
    const [searchParams,] = useSearchParams();
    const playerCode = location.pathname.replace("/player/", '').replace('-', '#').toUpperCase();
    const { height: windowHeight, width: windowWidth } = useWindowDimensions();

    const isOverlay = searchParams.get('overlay') ? true : false

    const updatePlayerInfo = (forceUpdate: boolean = true) => {
        socket.emit('updates', { code: playerCode, update: forceUpdate });
    }

    useEffect(() => {
        socket.on('connect', () => {
            updatePlayerInfo(false);
        })

        socket.on('disconnect', () => {
        });

        socket.on(playerCode, (p) => {
            setPlayerInfo(p);
        })

        return () => {
            socket.off('connect');
            socket.off('disconnect');
            socket.off(playerCode);
        }
    })

    return (
        <div className="playerBox" style={{height: windowHeight}}>
            <PlayerDisplay player={playerInfo} height={windowHeight} width={windowWidth} isOverlay={isOverlay}></PlayerDisplay>
            <div hidden={isOverlay}>
                <Button
                    variant='contained'
                    onClick={() => updatePlayerInfo(true)}
                >
                    update
                </Button>
                <p>
                    Use the following as a browser source:
                </p>
                <p>
                    {window.location.href}?overlay=true
                </p>
                <p>
                    Pressing update here updates the overlay in OBS
                </p>
                <p>
                    Add this page to OBS from 'Docks -&gt; Custom Browser Docks' to have the update button in OBS
                </p>
            </div>
        </div>
    )
}

export default Player
