import { Link, Tooltip } from '@mui/material';
import { GridColTypeDef, GridFilterInputMultipleValue, GridFilterItem, GridFilterOperator, GridRenderCellParams } from '@mui/x-data-grid';
import * as React from 'react';

// from https://github.com/mui/mui-x/blob/3e7bfb2a3b11d3728892b245e70928c7669a0bd6/packages/grid/x-data-grid/src/colDef/gridStringOperators.ts
const leagueFilterOperators: GridFilterOperator[] = [
  {
    label: 'Contains Any',
    value: 'isAnyOf',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!Array.isArray(filterItem.value) || filterItem.value.length === 0) {
        return null;
      }
      const filterItemValue = filterItem.value.map((val) => val.trim());
      const collator = new Intl.Collator(undefined, { sensitivity: 'base', usage: 'search' });

      return ({ value }): boolean =>
        value != null
          ? filterItemValue.some((filterValue: GridFilterItem['value']) => {
            // value is an array
            // if any of array matches return true
            return value.some((v: string) => collator.compare(filterValue, v) === 0);
          })
          : false;
    },
    InputComponent: GridFilterInputMultipleValue,
  },
  {
    label: 'Contains None',
    value: 'isNoneOf',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!Array.isArray(filterItem.value) || filterItem.value.length === 0) {
        return null;
      }
      const filterItemValue = filterItem.value.map((val) => val.trim());
      const collator = new Intl.Collator(undefined, { sensitivity: 'base', usage: 'search' });

      return ({ value }): boolean =>
        value != null
          // Added a ! here
          ? !filterItemValue.some((filterValue: GridFilterItem['value']) => {
            // value is an array
            // if any of array matches return true
            return value.some((v: string) => collator.compare(filterValue, v) === 0);
          })
          : false;
    },
    InputComponent: GridFilterInputMultipleValue,
  },
  {
    value: 'isEmpty',
    getApplyFilterFn: () => {
      return ({ value }): boolean => {
        // value is array
        return value.length === 0;
      };
    },
    requiresFilterValue: false,
  },
  {
    value: 'isNotEmpty',
    getApplyFilterFn: () => {
      return ({ value }): boolean => {
        // value is array
        return value.length !== 0;
      };
    },
    requiresFilterValue: false,
  },
]

const leagueType: GridColTypeDef = {
  type: 'string',
  width: 150,
  sortable: false,
  filterable: true,
  valueGetter: ({ value }) => {
    return value.map((v: any) => v.id);
  },
  renderCell: (params: GridRenderCellParams) => {
    let leagues = params.row.leagues;
    let cell = leagues.map((l: any) => {
      return (
        <Tooltip title={l.name} key={l.id}>
          <Link href={l.url} target="_blank" underline='none' rel="noopener" style={{color: 'black'}}>{l.id} </Link>
        </Tooltip>
      )
    });

    return (
      <div>
        {cell}
      </div>
    )
  },
  filterOperators: leagueFilterOperators,
}

export {
  leagueType,
  leagueFilterOperators
}
