import * as React from 'react';
import { DataGrid, GridColDef, GridFilterModel, GridFooter, GridFooterContainer, GridRenderCellParams, GridSortModel, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid';
import { useEffect, useState } from "react";
import axios from 'axios';
import * as DateTime from 'luxon';
import { useLocation } from 'react-router-dom';
import { Link, Typography } from '@mui/material';
import Rank from './utils/rank';
import { leagueType } from './utils/leaguesColumn';
import useWindowDimensions from './utils/windowDimensions';
import QueueButton from './utils/queueButton';
import { io } from 'socket.io-client';

const socketUrl = `${process.env.REACT_APP_API_URL_OVERRIDE || process.env.REACT_APP_API_URL}/players`.replace('http', 'ws');
const socket = io(socketUrl);

interface CustomToolbarProps {
  nextPlayer: string;
};

function CustomToolbar(props: CustomToolbarProps) {
  const now = DateTime.DateTime.local();
  let updatesAt = now.plus({ minutes: 1 });
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
      <Typography>
        {`Updating ${props.nextPlayer} at ${updatesAt.toFormat('HH:mm')}`}
      </Typography>
    </GridToolbarContainer>
  );
}

interface CustomFooterRProps {

};

function customFooter(props: CustomFooterRProps) {
  return (
    <GridFooterContainer>
      <GridFooter sx={{ border: 'none' }} />
      <div>
        Slippi Rank Icons by <Link href="https://twitter.com/AlexandreMisson/status/1404553609380085765" target='_blank' underline='none' rel="noopener">Alexandre Misson</Link>
      </div>
    </GridFooterContainer>
  )
}

export default function DataTable() {
  const location = useLocation();
  const [players, setPlayers] = useState([]);
  // const [leagues, setLeagues] = useState([]);
  const [filterModel, setFilterModel] = React.useState<GridFilterModel>();
  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: 'rating',
      sort: 'desc',
    },
  ]);
  const [nextPlayer, setNextPlayer] = useState("");
  const { height: windowHeight } = useWindowDimensions();

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Player',
      width: 120,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        let v: string = params.row.id.replace('#', '-');
        return <Link href={`https://slippi.gg/user/${v}`} target='_blank' underline='none' rel="noopener">{params.value}</Link>
      }
    },
    {
      field: 'id',
      headerName: 'Code',
      width: 100,
      sortable: false,
    },
    {
      field: 'rating',
      headerName: 'Rating',
      type: 'number',
      width: 90,
      valueGetter: ({ value }) => {
        return Math.round(value);
      },
      renderCell: (params: GridRenderCellParams) => {
        return <Rank rating={params.value} gamesPlayed={params.row.wins + params.row.losses} />
      }
    },
    {
      field: 'wins',
      headerName: 'Wins',
      width: 55,
    },
    {
      field: 'losses',
      headerName: 'Losses',
      width: 70,
    },
    {
      field: 'updated_at',
      headerName: 'Last Update',
      width: 150,
      type: "dateTime",
      valueFormatter: ({ value }) => {
        const now = DateTime.DateTime.local();
        const d = DateTime.DateTime.fromISO(value);
        const min = now.diff(d, "minutes");
        return `${Math.floor(min.minutes)} minute(s) ago`;
      },
    },
    {
      field: 'leagues',
      headerName: 'Leagues',
      ...leagueType
    },
    {
      field: 'addToQueue',
      headerName: 'Queue Refresh',
      sortable: false,
      width: 170,
      type: 'boolean',
      valueGetter: ({ value }) => {
        return value || false;
      },
      renderCell: (params: GridRenderCellParams) => {
        return (
          <QueueButton id={params.row.id} reset={params.value} updateNext={setNextPlayer} />
        )
      }
    },
  ];

  const fetchPlayerData = () => {
    axios.request({
      url: `${process.env.REACT_APP_API_URL_OVERRIDE || process.env.REACT_APP_API_URL}/players`,
      method: 'GET'
    })
      .then((res) => {
        setPlayers(res.data);
      })
      .catch((res) => {
        console.log(res);
      });
  };

  const fetchNextPlayerData = () => {
    axios.request({
      url: `${process.env.REACT_APP_API_URL_OVERRIDE || process.env.REACT_APP_API_URL}/queue/next`,
      method: 'GET'
    })
      .then((res) => {
        setNextPlayer(res.data);
      })
      .catch((res) => {
        console.log(res);
      });
  }

  useEffect(() => {
    // Initial setup
    const fetchLeagueData = () => {
      axios.request({
        url: `${process.env.REACT_APP_API_URL_OVERRIDE || process.env.REACT_APP_API_URL}/leagues`,
        method: 'GET'
      })
        .then((res) => {
          // get default filter if given in path
          if (location.pathname !== '/') {
            let path = location.pathname.slice(1).toUpperCase().split('/').filter((v) => v);
            setFilterModel({
              items: [
                {
                  columnField: "leagues",
                  operatorValue: "isAnyOf",
                  value: path,
                }
              ],
            });
          } else {
            // setLeagues(res.data);
            let l = res.data.filter((league: any) => !league.hiddenByDefault).map((league: any) => league.id)
            setFilterModel({
              items: [
                {
                  columnField: "leagues",
                  operatorValue: "isAnyOf",
                  value: l,
                }
              ],
            });
          }
        })
        .catch((res) => {
          console.log(res);
        });
    };

    fetchPlayerData();
    fetchLeagueData();
    fetchNextPlayerData();

    socket.on('queue', (nextPlayer) => {
      setNextPlayer(nextPlayer);
    });

    return () => {
      socket.off('queue')
    };
  }, [location])

  useEffect(() => {
    socket.on('table', (player) => {
      console.log(player);
      if (player.wins === 0 && player.losses === 0) {
        return;
      }
      setPlayers(players => {
        let i = players.findIndex((p: any) => p.id === player.id);
        let updatedPlayers: any = [...players];
        if (i > -1) {
          updatedPlayers[i] = player;
          updatedPlayers[i].addToQueue = true;
        }
        else {
          updatedPlayers.push(player);
        }
        if (player.id === nextPlayer) {
          fetchNextPlayerData();
        }

        return updatedPlayers;
      });
    })

    return () => {
      socket.off('table');
    };

  }, [nextPlayer]);

  return (
    <div style={{ height: windowHeight, width: '100%' }}>
      <DataGrid
        rows={players}
        columns={columns}
        pageSize={25}
        rowsPerPageOptions={[25]}
        sortModel={sortModel}
        sortingMode={"client"}
        onSortModelChange={(model) => setSortModel(model)}
        sortingOrder={['desc', 'asc']}
        components={{
          Toolbar: CustomToolbar,
          Footer: customFooter
        }}
        componentsProps={{ toolbar: { nextPlayer } }}
        disableColumnMenu={true}
        filterModel={filterModel}
        onFilterModelChange={(model) => setFilterModel(model)}
        initialState={{
          columns: {
            columnVisibilityModel: {
              addToQueue: true
            }
          }
        }}
      />
    </div>
  );
}
