import { Button } from '@mui/material';
import axios from 'axios';
import * as React from 'react';
import * as DateTime from 'luxon';

const addToQueue = async (code: string) => {
    return axios.request({
        url: `${process.env.REACT_APP_API_URL_OVERRIDE || process.env.REACT_APP_API_URL}/queue`,
        method: 'POST',
        data: {
            players: [code],
        }
    })
        .then((res) => {
            return res.data;
        })
        .catch((res) => {
            console.log(res);
        });
}

interface QueueButtonProps {
    id: string,
    reset: boolean,
    updateNext(arg0: string): void;
}

export default function QueueButton(props: QueueButtonProps) {
    let [textContent, setTextContent] = React.useState("");

    React.useEffect(() => {
        if (props.reset) {
            setTextContent("");
        }
    }, [props.reset])

    return (
        <Button disabled={(textContent) ? true : false} onClick={async (e) => {
            e.stopPropagation();
            let v = await addToQueue(props.id)
            if (Array.isArray(v) && v.length > 0) {
                const now = DateTime.DateTime.local();
                let updatesAt = now.plus({ minutes: v[0].queuePosition });
                setTextContent(`Refreshing at ${updatesAt.toFormat('HH:mm')}`);
                if (v[0].queuePosition === 1) {
                    props.updateNext(props.id);
                }
            }
        }}>{(textContent) ? textContent : "Queue"}</Button>
    );
}
